@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
:root {
  --primary: #4c617b;
  --secondary: #89abde;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient-bg {
  background: linear-gradient(to right, #4c617b, #5e7599);
}

.gradient-bg-button {
  background: linear-gradient(to right, #89abde, #4c617b);
}
.gradient-bg-button:hover {
  background: linear-gradient(to right, #4c617b, #5e7599);
}
.gradient-hover:hover {
  background: linear-gradient(to right, #89abde, #4c617b);
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: 15px;
  margin-bottom: 5px;
}

#root {
  min-height: 100%;
}
body {
  height: 100%;
}
.wrapper {
  /* background: #4a5f78; */
}

.custom-container {
  width: 100%;
  max-width: 1120px;
  height: 100%;
  margin: 0 auto;
}
.button-nav {
  background: transparent;
  border-radius: 20px;
  border: 2px solid #4c617b;
  font-size: 15px;
  color: #4c617b;
  width: 100%;
  font-weight: 500;
  display: inline-block;
  transition: all 0.1s;
}

.button-nav a {
  padding-top: 4px;
  padding-bottom: 4px;
}

.button-nav:hover {
  background: linear-gradient(to right, #647da3, #4c617b);
  color: #fff;
}
.custom-datepicker {
  position: relative;
}
.table-dashboard thead th {
  height: 3.5rem;
  background: #eee;
  font-weight: 600;
}

.table-dashboard tbody tr {
  height: 2.5rem;
}

.table-dashboard tbody tr td:not(:last-child) {
  font-size: 15px;
  height: 3rem;
  font-weight: 600;
  text-transform: uppercase;
  background: #849dbb;
  padding: 10px;
  /*  border-top: 2px solid #4c617b; */
  border-right: 2px solid #4c617b;
}
.table-dashboard tbody tr td:last-child {
  background-color: transparent;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  padding-left: 10px;
  color: #ebe7e7;
}
.table-worklog thead th {
}

.print-icon,
.edit-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #eee;
}

.grid-4 {
  grid-template-columns: repeat(4, 1fr);
}

@media only screen and (max-width: 1024px) {
  .grid-4 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .grid-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 500px) {
  .grid-4 {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 500px) {
  .grid-1 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid-1 {
  grid-template-columns: 1fr;
}
.mobile-remove {
  display: block;
  align-content: center;
}

@media only screen and (max-width: 768px) {
  .mobile-remove {
    display: none !important;
  }
}
@media only screen and (max-width: 1050px) {
  .table-worklog thead th {
    width: 100px !important;
    padding: 0;
  }
}

/* @media only screen and (max-width: 850px) {
  .print-icon {
    display: none;
  }
  .edit-icon {
    display: none;
  }
} */

.notification {
  justify-content: flex-start;
  position: relative;
}
.notification p {
  font-size: 16px;
}

.zaposlenici-container {
  justify-content: flex-start;
}
@media only screen and (max-width: 1050px) {
  .zaposlenici-container {
    justify-content: center;
  }

  .notification {
    justify-content: center;
    left: -18px;
  }
}

@media only screen and (max-width: 768px) {
  .notification {
    transform: scale(0.85);
  }
  .notification p {
    font-size: 18px;
  }

  .notification-message p {
    font-size: 16px;
    font-weight: 600;
  }
}
/* Table header */

.table-dashboard thead th:not(:last-child) {
  min-width: 200px;
}

@media only screen and (max-width: 700px) {
  .table-dashboard {
    padding: 15px;
  }
  .table-dashboard thead th:not(:last-child) {
    min-width: 200px;
  }
}

@media only screen and (max-width: 520px) {
  .table-dashboard {
    padding: 15px;
  }
  .table-dashboard thead th:not(:last-child) {
    min-width: 130px;
  }
}
.custom-grid-2 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 5rem;
}

@media only screen and (max-width: 1050px) {
  .custom-grid-2 {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    gap: 10px;
  }
}

.calendar-icon {
  position: absolute;
  right: 10px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2em;
}

.react-datepicker-wrapper input {
  text-align: center;
  cursor: pointer;
  border: 1px solid #eee;
  text-transform: capitalize; /* Capitalize first letter */
}

.create-edit-form {
  background-color: #f2f8ff;
  border-radius: 5px;
  padding: 20px;
}
.create-edit-form h3 {
  background: #e1e4e7da;
  padding: 8px 20px;
  border-radius: 10px;
  font-weight: 500;
  color: #363434;
}
.create-edit-form .form-data .form-control {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  margin-top: 20px;
}

.create-edit-form .form-item input {
  border: 0;
  margin-top: 5px;
  padding: 0;
  padding-bottom: 5px;
  display: block;
  font-weight: 500;
  color: #3c3e41;
  width: 90%;
  border-bottom: 2px solid #5e7599;
  background-color: transparent;
}

.create-edit-form .form-item select {
  background: transparent;
}

.create-edit-form .form-item label {
  margin-bottom: 15px;
}

.create-edit-form .form-item input:focus {
  border: 0;
  border-bottom: 2px solid #4b5a72;
  outline: none;
}

.create-edit-form .form-data {
  display: grid;

  padding: 10px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

@keyframes blink-bg {
  0% {
    background-color: #3b82f6; /* bg-blue-500 */
    transform: scale(1);
    color: #fff;
  }
  50% {
    background-color: #d1d5db; /* bg-gray-300 */
    transform: scale(1.08);
    color: #333;
  }
  100% {
    background-color: #3b82f6; /* bg-blue-500 */
    transform: scale(1);
    color: #fff;
  }
}

.blinking-button {
  border: 2px solid transparent;
  animation: blink-bg 2s infinite;
  position: relative;
}
.blinking-button:hover {
  animation: step-end;
}

@media only screen and (max-width: 540px) {
  .create-edit-form .form-data {
    gap: 10px;
  }

  .create-edit-form .form-data input[name='startDate'] {
    padding: 10px;
  }

  .create-edit-form .form-item {
    width: 100%;
  }

  .create-edit-form .form-item label {
    font-size: 15px;
  }
  .create-edit-form .form-item input {
    min-width: 100%;
  }
}

@media only screen and (max-width: 540px) {
  .create-edit-form .form-data {
    grid-template-columns: 1fr;
  }

  .create-edit-form .form-item input {
    width: 100%;
    min-width: 200px;
  }
}

.contact-form-container {
  display: grid;
  grid-template-columns: 500px 1fr;
  gap: 40px;
}

@media only screen and (max-width: 1100px) {
  .contact-form-container {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .contact-form-container form {
    min-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .contact-form-container {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .contact-form-container form {
    min-width: 100%;
  }
}

.contact-form {
  background: #fff;
  padding: 20px;
}

.contact-details {
  color: #eee;
  font-size: 18px;
  font-family: Roboto;
}

.contact-form input,
.contact-form textarea {
  background: #dbdddd7e;
  font-size: 14px;
}

.month-total p {
  color: #eee;
}

@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

.contact h3 {
  font-size: 25px;
}
.contact p {
  font-size: 18px;
}

@media only screen and (max-width: 600px) {
  .contact h3 {
    font-size: 20px;
  }
  .contact p {
    font-size: 16px;
  }

  .contact img {
    width: 45px;
    height: auto;
  }

  .contact h2 {
    font-size: 20px;
  }

  .contact-form-container {
    gap: 10px;
  }
}

/*Checkbox effects*/

.checkbox-wrapper-24 .checkbox {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
}

.checkbox-wrapper-24 label {
  display: inline-block;
  color: #333;
  cursor: pointer;
  position: relative;
}
.checkbox-wrapper-24 label span {
  display: inline-block;
  position: relative;
  background-color: transparent;
  width: 22px;
  height: 22px;
  transform-origin: center;
  border: 2px solid #333;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 5px;
  transition: background-color 150ms 200ms,
    transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
}
.checkbox-wrapper-24 label span:before {
  content: '';
  width: 0px;
  height: 2px;
  border-radius: 2px;
  background: #333;
  position: absolute;
  transform: rotate(34deg);
  top: 9px;
  left: 5px;
  transition: width 50ms ease 50ms;
  transform-origin: 0% 0%;
}
.checkbox-wrapper-24 label span:after {
  content: '';
  width: 0;
  height: 2px;
  border-radius: 2px;
  background: #333;
  position: absolute;
  transform: rotate(305deg);
  top: 12px;
  left: 7px;
  transition: width 50ms ease;
  transform-origin: 0% 0%;
}
.checkbox-wrapper-24 label:hover span:before {
  width: 5px;
  transition: width 100ms ease;
}
.checkbox-wrapper-24 label:hover span:after {
  width: 10px;
  transition: width 150ms ease 100ms;
}

.checkbox-wrapper-24 input[type='checkbox'] {
  display: none;
}
.checkbox-wrapper-24 input[type='checkbox']:checked + label span {
  background-color: #333;
  transform: scale(1.12);
}
.checkbox-wrapper-24 input[type='checkbox']:checked + label span:after {
  width: 10px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
.checkbox-wrapper-24 input[type='checkbox']:checked + label span:before {
  width: 5px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
.checkbox-wrapper-24 input[type='checkbox']:checked + label:hover span {
  background-color: #333;
  transform: scale(1.12);
}
.checkbox-wrapper-24 input[type='checkbox']:checked + label:hover span:after {
  width: 10px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
.checkbox-wrapper-24 input[type='checkbox']:checked + label:hover span:before {
  width: 5px;
  background: #fff;
  transition: width 150ms ease 100ms;
}

.remove-label label {
  display: none;
}
