@media print {
  @page {
    size: A4 portrait;
  }

  body {
    font-family: Arial, sans-serif;
    font-size: 14px;
    margin: 0.5cm;
  }

  table {
    width: 100%;
    padding: 3px;
    border-collapse: collapse;
    margin: 10px 0;
  }

  th,
  td {
    border: 1px solid #ddd;
    width: 50px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
    margin-top: 2rem;
  }

  /* Add more print-specific styles as needed */
}
